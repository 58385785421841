import { Link } from "gatsby"
import React from "react"

import styles from "./index.module.scss"
import logoVipco from "../../images/logo-vipco.svg"

type HeaderProps = {
  siteTitle: string
}

const Header = ({ siteTitle }: HeaderProps) => (
  <header className={styles.header}>
    <Link to="/">
      <img src={logoVipco} alt={siteTitle} />
    </Link>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
